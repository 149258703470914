import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PopupMessage from "./PopupMessage.web";
import { logo, loginbg } from "./assets";
import MessageBox from "../../../components/src/messageBox";
export const configJSON = require("./config");
const LoginContainer = styled(Box)({
  '& .imageViewBoxs': {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
    backgroundImage: `url(${loginbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  },
  "& .boxNextViews": {
    width: "93.057%",
  },
  "& .logohereText": {
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "white",
  },
  "& .imgPic ": {
    width: "74.7%",
    objectFit: 'contain'
  },
  "& .boxTextStyles": {
    flexDirection:'column',
    alignItems:"center",
    display:"flex"
  },
  "& .msgText": {
    color: "white",
    lineHeight: "1.2",
    fontFamily: "Poppins",
    fontSize: "38px",
    marginTop:"8px",
    fontWeight: 700,
    width:"80%"
  },
  "& .smallText": {
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 100,
    fontSize: "18px",
    color: "white",
    marginTop: "13px",
    width: "83.584%",
    '@media (max-width:412px)': {
      paddingLeft: '22%',
      paddingRight: '22%',
      textAlign: "center",
    },
    '@media (max-width:360px)': {
      paddingLeft: '22%',
      paddingRight: '22%',
      textAlign: "center",
    },
  },
  "& .backgroundColorImages": {
    backgroundColor: '#325962',
  },
  "& .loginText": {
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.005em',
    textAlign: 'center',
    color: '#0F172A',
  },
  " & .yourEmailContaians": {
    marginTop: '20px'
  },
  " & .textStyles1": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    color: "#334155",
    marginTop: '24px'
  },

  " & .textStyles": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    color: "#334155",
  },
  "& .dontText": {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#334155',
  },
  "& .textspan": {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '700',
    fontSize: '18px',
    color: '#237182',
    cursor: 'pointer',
    userSelect: 'none'
  },
  "& .donotAccountView": {
    marginTop: "32px"
  },
  "& .remember": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    color: "#0F172A"
  },
  "& .root": {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  "& .input": {
    '&::before': {
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
  },
  " & .errorMessageStyles": {
    color: 'red',
  },
  "& input[type='password']": {
    "-webkit-text-security": "disc",
  },

  "& input[type='password'].asterisk": {
    "-webkit-text-security": "square",
  },

})
const inputStyle = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: "#94A3B8",
} as const

const RightHalf = styled("div")({
  paddingLeft: "48px",
  paddingRight: "48px",
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 96px)",
  height: "100vh",
  borderBottomLeftRadius: "20px",
  borderTopLeftRadius: "20px",
  backgroundColor: "white",
  overflowY: "auto",
})
const LoginForm = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",

})

const InputGroup = styled("div")({
  marginBottom: "15px",
  width: "100%",
})

const InputField = styled("input")({
  width: "100%",
  padding: "15px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  fontWeight: 400,
  fontFamily: "'Poppins', sans-serif",
  userSelect: "auto",
  backgroundColor: "#FFFFFF",
  fontSize: "16px",
  outline: "none",
  "&:focus": {
    border: "1px solid #CBD5E1",
  },
  marginTop: '2px',
  boxSizing: 'border-box',
})

const PasswordVisibilityIcon = styled(VisibilityOffOutlinedIcon)({
  position: 'absolute',
  right: "50px",
  cursor: "pointer",
  color: "#94A3B8",
  backgroundColor: "#fff",
  height: "24px",
  width: "24px",
  padding: "10px",
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#F2F3F4",
  }
})

const PasswordDisabilityIcon = styled(VisibilityOutlinedIcon)({
  position: 'absolute',
  right: "50px",
  cursor: "pointer",
  color: "#94A3B8",
  backgroundColor: "#fff",
  height: "24px",
  width: "24px",
  padding: "10px",
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#F2F3F4",
  }
})

const CheckboxGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "15px",
  borderRadius: "15px",
  height: "20px",
})

const PasswordInputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  userSelect: "none",

})
const ForgetPasswordLink = styled("a")({
  textDecoration: "none",
  borderBottom: "none",
  color: "rgba(35, 113, 130, 1)",
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 600,
  fontSize: "16px",
  userSelect: "none",
  cursor: "pointer"
});
const CheckboxLabel = styled("label")({
  display: "flex",
  alignItems: "center",
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 400,
  fontSize: "16px",
  userSelect: "none"
});

const CustomCheckbox1 = styled('input')({
  '-moz-appearance': 'none',
  '-webkit-appearance': 'none',
  width: '20px',
  height: '20px',
  borderRadius: '7px',
  outline: 'none',
  border: '1px solid #6c6969',
  cursor: 'pointer',
  marginRight: '10px',
  position: 'relative',
  marginLeft: '-1px',
  '&:checked': {
    backgroundColor: '#237182',
    borderColor: '#237182',
  },
  '&:checked::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '5px',
    width: '6px',
    height: '10px',
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
});
const webStyles = {
  errStyle1: {
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif",
    letterSpacing: "0.00938em",
    lineHeight: "1.5",
    color: "red",
    margin: "5px 3px",
    fontSize: "14px",
    display: "hidden",
    userSelect: "none"
  } as const,
  formscss: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    margin: "0 auto"
  } as const,
  boxstyle: {
    display: "flex"
  } as const,
  signupTextStylewith2: {
    width: "100%",
    marginTop: '15px',
    border: "none",
    padding: "16px",
    cursor: "pointer",
    color: "white",
    borderRadius: "8px",
    background: "#237182",
    fontSize: "16px",
    textTransform: 'none',
    fontWeight: 600,
    userSelect: "none",
    fontFamily: "Poppins",
    lineHeight: "24px",
    textAlign: "center"
  } as const,
  signupTextStyle2: {
    width: "100%",
    marginTop: '15px',
    border: "none",
    padding: "16px",
    cursor: "pointer",
    color: "#64748B",
    borderRadius: "8px",
    background: "#F1F5F9",
    fontSize: "16px",
    textTransform: 'none',
    fontWeight: 600,
    userSelect: "none",
    fontFamily: "Poppins",
    lineHeight: "24px",
    textAlign: "center"
  } as const
}

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
       <ThemeProvider theme={theme}>
        {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status} stylePosition={{ top: "25px", left: "62%", transform:"none", boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)" }}/>}
       <LoginContainer>
         <Grid container>
           <Grid item xs={12} md={6}>
             <Box
               className="imageViewBoxs"
             >
               <Box className="boxNextViews">
                 <Typography className="logohereText">
                   {configJSON.logoTextHere}
                 </Typography>
                 <img src={logo} className="imgPic" />
                 <Grid container>
                   <Grid item xs={12}>
                     <Box className="boxTextStyles">
                       <Typography className="msgText">
                         Your new construction management tool.
                       </Typography>
                       <Typography className="smallText">
                         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.
                       </Typography>
                     </Box>
                   </Grid>
                 </Grid>

               </Box>
             </Box>
           </Grid>
           <Grid item xs={12} md={6} className="backgroundColorImages">
             <RightHalf >
               <LoginForm data-test-id="loginForm">
                 <Typography component="h2" className="loginText">
                   {configJSON.btnTextLogin}
                 </Typography>
                 <InputGroup>
                   <Typography className="textStyles1">{configJSON.placeHolderEmail}</Typography>
                   <InputField
                     data-test-id="txtInputEmail"
                     style={{ ...inputStyle, color: this.state.email ? '#0F172A' : '#94A3B8', borderColor: this.state.errors.email || this.state.emailErrorFail ? "#F87171" : "#CBD5E1" }}
                     placeholder="Enter your email"
                     type="text"
                     id="email"
                     name="email"
                     value={this.state.email}
                     onChange={this.handleInputChange}
                     required
                   />
                   <FormHelperText style={webStyles.errStyle1}>
                     {this.state.errors.email || this.state.emailErrorFail}
                   </FormHelperText>
                 </InputGroup>
                 <InputGroup className="password-group">
                   <Typography className="textStyles">{configJSON.placeHolderPassword}</Typography>
                   <PasswordInputContainer className="password-input-container" >
                     <InputField
                       data-test-id="txtInputPassword"
                       style={{ ...inputStyle, color: this.state.password ? '#0F172A' : '#94A3B8' }}
                       placeholder="Enter your password"
                       type={this.state.showPassword? "name":"text"}
                       id="password"
                       name="password"
                       value={this.state.showPassword ? this.state.asterisk : this.state.originalPassword}
                       onChange={(e:any)=>this.handlePasswordChanged(e)}
                       required
                     />

                     {this.state.showPassword ? (
                       <PasswordVisibilityIcon
                         data-test-id="imgTag"
                         className="password-icon visible-icon"
                         onClick={this.toggleVisibility}
                       />
                     ) : (
                       <PasswordDisabilityIcon
                         data-test-id="imgTag"
                         className="password-icon visible-icon"
                         onClick={this.toggleVisibility}
                       />
                     )}
                   </PasswordInputContainer>

                   <Typography data-test-id="txtPasswordemail" style={webStyles.errStyle1}>{this.state.errors.password || this.state.passErrorFail}</Typography>
                 </InputGroup>
                 <CheckboxGroup>
                   <CheckboxLabel data-test-id="btnRememberMe" htmlFor="remember">
                     <CustomCheckbox1
                       type="checkbox"
                       id="remember"
                       name="remember"
                       checked={this.state.checkedRememberMe}
                       data-test-id="btnRememberMecustome"
                       onChange={(e: any) => this.setRememberMe(e.target.value)}
                     />
                     <Typography className="Remember"> Remember me</Typography>
                   </CheckboxLabel>
                   <ForgetPasswordLink data-test-id="btnForgotPassword" onClick={this.navigateToForgotPassword} className="forgetPasswordLink">Forgot Password?</ForgetPasswordLink>
                 </CheckboxGroup>
                 <Button
                   onClick={() => this.doEmailLogInn()}
                   data-test-id="submitBtn"
                   type="submit"
                   variant="contained"
                   color="primary"
                   disabled={!(this.state.validations.emailValid && this.state.validations.passwordsMatch)}
                   fullWidth
                   style={(this.state.validations.emailValid && this.state.validations.passwordsMatch) ? webStyles.signupTextStylewith2 : webStyles.signupTextStyle2}>
                   Log In
                 </Button>
                 <Box className="donotAccountView">
                   <Typography className="dontText">{configJSON.DonNotaccount} {" "}
                     <Typography component={"span"} data-test-id="signup-navigation" onClick={this.handleSignupNavigate} className="textspan">{configJSON.signupText}</Typography>
                   </Typography>
                 </Box>
               </LoginForm>
             </RightHalf>
             <PopupMessage
               open={this.state.showPopup}
               message={"Password reset successfully!"}
               type={"success"}
               onClose={this.handleClose}
             />
           </Grid>
         </Grid>
       </LoginContainer>
     </ThemeProvider>
     
       // Customizable Area End
    );
  }
}
