// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  messageToggle: boolean;
  tostifymessage: string;
  status: string;
  loading: boolean;
  isAddMemberModalOpen: boolean;
  users: any;
  inputName: string;
  selectedMembers: any;
  newValue: string;
  anchorEl: any;
  filteredUsers: any;
  selectedUsers: any;
  teamName: string;
  teamId:number;
  memberError:string;
}

interface SS {
  id: any;
}

export default class AddNewMemberController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMembersListCallId: string = "";
  postCreateMemberCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      messageToggle: true,
      tostifymessage: "",
      status: "",
      loading: false,
      isAddMemberModalOpen: false,
      inputName: "",
      selectedMembers: [],
      newValue: "",
      anchorEl: null,
      teamName: "",
      users: [],
      filteredUsers: [],
      selectedUsers: [],
      teamId:0,
      memberError:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    const historyLocationState = this.props.navigation?.getParam("title" );
    const id = this.props.navigation?.getParam("id") 

    if (historyLocationState) {
      this.setState({
        teamName: historyLocationState,
      });
      this.setState(
        {
          messageToggle: true,
          tostifymessage:"You’ve created a new team, start adding members to it.",
          status: "success",
        },
        () => {
          this.closeMessageBox();
        }
      );
    }
    if(id){
      this.setState({
        teamId:id
      })
    }
    this.getMembersListApi();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiCallId === this.getMembersListCallId) {
        this.handleMembersListResponse(responseJson);
      }
      if (apiCallId === this.postCreateMemberCallId) {
        this.handleCreateMemberResponse(responseJson);
      }
    }
  }

  getMembersListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "Application/json",
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetMembersListEndPoint}/list_all_members`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleMembersListResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        users: responseJson.members,
      });
    } else {
      this.setState({
        users: [],
      });
    }
  };

  closeMessageBox = () => {
    setTimeout(() => {
      this.setState({ messageToggle: false, tostifymessage: "", status: "" });
    }, 2000);
  };

  backBtn = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "TeamList");
    this.send(msg);
  };

  handleOpenModal = () => {
    this.getMembersListApi();
    this.setState({
      isAddMemberModalOpen: true,
    });
  };

  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAddMemberModalOpen: !prev.isAddMemberModalOpen,
    }));

  handleMemberAdd = (user: any) => {
    this.setState((prevState) => ({
      selectedUsers: [
        ...prevState.selectedUsers,
        { id:user.id, value: user.user_name, label: user.user_name },
      ],
    }));
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputName: inputValue });
  };

  handleAddMemberData = async()=>{
    const data = this.state.selectedUsers.map((obj: any) => parseInt(obj.id)); 
    console.log(data)
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.ContentType,
        token: token,
      };
     
      const body = {    
         account_ids:data
      }
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.postCreateMemberCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.postCreateMemberEndPoint}/${this.state.teamId}/add_members`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
  handleCreateMemberResponse = (responseJson:any)=>{
       if(!responseJson.error){
        if (responseJson.message == "No members added"){
          console.log("already added in the list ")
        }
        this.setState({
          isAddMemberModalOpen:false,
          selectedMembers:[],
          selectedUsers:[]
        })
        
       }else{
       if(responseJson.error == "No members selected for addition"){
           this.setState({
            memberError:responseJson.error
           })
       }else if(responseJson.error == "Account must exist"){
        this.setState({
          memberError:"Account must exist"
         })
       }
       }
  }

}

// Customizable Area End
