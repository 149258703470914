import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { Component, ChangeEvent } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  email:{
    current_email: string,
    new_email: string,
    password: string
  },
  emailError:{
    current_email: string,
    new_email: string,
    password: string
  },
  popUpToggle:boolean;
  passwordWarningToggle:boolean;
  // Customizable Area Start
  name:string
  currentPassword:string;
  currentEmailPassword: string;
  newPassword: string;
  confirmPassword: string;
  requirements: {
    length: boolean;
    upperLower: boolean;
    number: boolean;
    specialChar: boolean;
  };
  triggerConfirmPasswordMsg:boolean;
  showCurrentPassword:boolean;
  showEmailCurrentPassword:boolean;
  showPassword:boolean;
  showConfirmPassword:boolean;
  originalCurrentPassword:string;
  originalPassword:string;
  originalConfirmPassword:string;
  asteriskCurrent:string;
  asteriskEmailCurrent:string;
  asterisk:string;
  asteriskConfirm:string;
  messageToggle:boolean;
  tostifymessage:string;
  status:string;
  tempToggle:boolean;
  currentPasswordError:string;
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updatePasswordApiId:string = "";
  editEmailChangeId:string = "";
  currentPasswordCheckId:string = "";
  delayInputTimeoutId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentPassword: "",
      currentEmailPassword:"",
      newPassword: "",
      name:"",
      confirmPassword: "",
      requirements: {
        length: false,
        upperLower: false,
        number: false,
        specialChar: false,
      },
      triggerConfirmPasswordMsg:false,
      showCurrentPassword:true,
      showEmailCurrentPassword:true,
      showPassword:true,
      showConfirmPassword:true,
      originalCurrentPassword:"",
      originalPassword:"",
      originalConfirmPassword:"",
      asteriskCurrent:"",
      asteriskEmailCurrent: "",
      asterisk:"",
      asteriskConfirm:"",
      messageToggle:false,
      tostifymessage:"",
      status:"",
      email:{
        current_email: "",
        new_email: "",
        password: ""
      },
      emailError:{
        current_email: "",
        new_email: "",
        password: ""
      },
      popUpToggle:false,
      passwordWarningToggle:false,
      tempToggle:false,
      currentPasswordError:"",
      isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.updatePasswordApiId === apiRequestCallId) {
        if (responseJson.errors) {
          this.setState({ messageToggle:true, tostifymessage:responseJson.errors[0].password, status:"failed" });  
        } else {
          await setStorageData("message", JSON.stringify({ messageToggle: true, tostifymessage: 'Password has been changed successfully!', status: "success" }));
          await this.props.navigation.navigate("CustomisableUserProfiles");
        }
        this.closeMessageBox();
        this.setState({ isLoading:false });
      }

      if(this.editEmailChangeId === apiRequestCallId){
        if (responseJson.error) {
          this.setState({ emailError:{...this.state.emailError, password:responseJson.error } });  
        } else {
          await setStorageData("message", JSON.stringify({ messageToggle: true, tostifymessage: 'Email has been changed successfully!', status: "success" }));
          await this.props.navigation.navigate("CustomisableUserProfiles");
        }
      }

      if(this.currentPasswordCheckId === apiRequestCallId){
        if (responseJson.errors) {
          this.setState({ currentPasswordError:responseJson.errors[0].password });  
        } else {
          this.setState({ currentPasswordError:"" });
        }
      }
    }
  
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(){
    const current_email = await getStorageData("email");
    if(current_email)
    this.setState({ email:{ ...this.state.email, current_email }})
    this.navigatePage();
  }
  
  validatePassword(password: string) {
    const length = password.length >= 8;
    const upperLower = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    this.setState({
      requirements: {
        length,
        upperLower,
        number,
        specialChar,
      },
    });
  }

  handleCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
      const { originalCurrentPassword } = this.state;
      const inputEvent = e.nativeEvent as InputEvent;
      const input = e.target as HTMLInputElement;
      const cursorPosition = input.selectionStart || 0;
    
      if (!inputEvent || !inputEvent.inputType || value === " ") {
        return; }

      let origi ;
    
      if (inputEvent.inputType === 'insertText' && inputEvent.data) {
        const data = inputEvent.data;
        origi = originalCurrentPassword.slice(0, cursorPosition - 1) + data + originalCurrentPassword.slice(cursorPosition - 1);
        const updatedCursorPositionCurrent = cursorPosition + data.length;
        input.setSelectionRange(updatedCursorPositionCurrent - 1, updatedCursorPositionCurrent - 1);} 
      else {origi = value;}
    
      this.setState(
        {originalCurrentPassword: origi,
          asteriskCurrent: "*".repeat(origi.length),
          currentPassword: value});
  };

  handleNewPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const { originalPassword } = this.state;
      const inputEvent = e.nativeEvent as InputEvent;
      const input = e.target as HTMLInputElement;
      const cursorPosition = input.selectionStart || 0;
    
      if (!inputEvent || !inputEvent.inputType || value === " ") {
        return;
      }
    
      let origi;
    
      if (inputEvent.inputType === 'insertText' && inputEvent.data) {
        const data = inputEvent.data;
        origi = originalPassword.slice(0, cursorPosition - 1) + data + originalPassword.slice(cursorPosition - 1);
        const updatedCursorPosition = cursorPosition + data.length;
        input.setSelectionRange(updatedCursorPosition - 1, updatedCursorPosition - 1);
      } else {
        origi = value;
      }
    
      this.setState(
        {
          originalPassword: origi,
          asterisk: "*".repeat(origi.length),
          newPassword: value,
        },
        () => {
          this.validatePassword(this.state.originalPassword);
        }
      );

  };

  handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { originalConfirmPassword } = this.state;
    const inputEvent = e.nativeEvent as InputEvent;
    const input = e.target as HTMLInputElement;
    const cursorPosition = input.selectionStart || 0;
  
    if (!inputEvent || !inputEvent.inputType || value === " ") {
      return;
    }
  
  let origi
    if (inputEvent.inputType === 'insertText' && inputEvent.data) {
      const data = inputEvent.data;
      origi = originalConfirmPassword.slice(0, cursorPosition - 1) + data + originalConfirmPassword.slice(cursorPosition - 1);
      const updatedCursorPositionConfirm = cursorPosition + data.length;
      input.setSelectionRange(updatedCursorPositionConfirm - 1, updatedCursorPositionConfirm - 1);}else 
     {
      origi = value; }
  
    this.setState({originalConfirmPassword: origi,
        asteriskConfirm: "*".repeat(origi.length),
        confirmPassword: value,});
  
    this.setState({ triggerConfirmPasswordMsg: true });
  };

  handleEmailCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { password } = this.state.email;
    let origi = "";

    const inputType = (e.nativeEvent as InputEvent).inputType;

    if (inputType === "deleteContentBackward") {
      origi = password.slice(0, password.length - 1);
    } else {
      origi = password + value[value.length - 1];
    }

    this.setState({
      asteriskEmailCurrent: "*".repeat(origi.length),
      currentEmailPassword: value,
      email: { ...this.state.email, password: origi }
    });
  };

  desiableCurrentEmailPasswordCheck = () =>{
    return this.state.email.password == "";
  }

  desiableCheck() {
    let toggle = Object.values(this.state.requirements).every(Boolean);
    let toggleSec = this.state.originalPassword === this.state.originalConfirmPassword;
    let currentPasswordError = this.state.currentPasswordError === ""
    return toggle && toggleSec && currentPasswordError;
  }
  errorCheck=()=>{
  if(this.state.triggerConfirmPasswordMsg)
   return !(this.state.originalPassword == this.state.originalConfirmPassword)
  }
  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword}));
  }

  toggleConfirmVisibility = () => {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword}));
  }

  toggleCurrentVisibility = () => {
    this.setState(prevState => ({
      showCurrentPassword: !prevState.showCurrentPassword}));
  }

  toggleEmailCurrentVisibility = () => {
    this.setState(prevState => ({
      showEmailCurrentPassword: !prevState.showEmailCurrentPassword}));
  }

  resetAllPasswordField = () => {
   this.setState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    requirements: {
      length: false,
      upperLower: false,
      number: false,
      specialChar: false,
    },
    triggerConfirmPasswordMsg:false,
      showCurrentPassword:true,
      showPassword:true,
      showConfirmPassword:true,
      originalCurrentPassword:"",
      originalPassword:"",
      originalConfirmPassword:"",
      asteriskCurrent:"",
      asterisk:"",
      asteriskConfirm:"",
      currentPasswordError:""
   });
  }

  savePassword = async () => {
    this.setState({ isLoading:true });
    const token1 = await getStorageData("token");
    const header = {
      "Content-Type": "application/json"
    };

    const payload = {
      "data": {
          "token": token1,
          "current_password": this.state.originalCurrentPassword,
          "new_password": this.state.originalPassword,
          "confirm_password": this.state.originalConfirmPassword
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/passwords/change_password`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  closeMessageBox = () => {
    setTimeout(()=>{
     this.setState({messageToggle:false, tostifymessage:"", status:""})
    },3000);
  }

  emailChangeBackBtn = () =>{
    this.props.navigation.navigate("CustomisableUserProfiles");
  }

  backPasswordBtn = () =>{
    if(!this.state.originalConfirmPassword && !this.state.originalCurrentPassword && !this.state.originalPassword)
    this.props.navigation.navigate("CustomisableUserProfiles");
    else {
      this.setState({ passwordWarningToggle:true });
    } 
  }

  continuePageChanges = ()=> {  
     this.cancelPasswordPopUpToggle();
     this.props.navigation.navigate("CustomisableUserProfiles");
  }

  cancelPasswordChange = () =>{
    this.setState({ passwordWarningToggle:false });
  }

  resetAllEmail = () => {
    this.setState({
      email: {
        ...this.state.email,
        new_email: "",
        password: ""
      },
      emailError: {
        current_email: "",
        new_email: "",
        password: ""
      }
    });
  }

  cancelPasswordPopUpToggle = () => {
    this.setState({
      popUpToggle: !this.state.popUpToggle,
      email: { ...this.state.email, password: "" },
      asteriskEmailCurrent: "",
      currentEmailPassword: "",
      emailError:{ ...this.state.emailError, password: "" }
    });
  }

  handleChangeEmail = (name: string, value: string) => {
    this.setState({ email: { ...this.state.email, [name]: value } },()=>{
      this.validateEmail(name,value);
    });
  }
  validateEmail = (name: string, value: string)=>{
    const error = {...this.state.emailError}
    const trimmedVal = value.trim();
  
    const setError = (field: keyof typeof error, message: string) => {
      error[field] = message;
    };
  
    if (trimmedVal === "") {
      setError(name as keyof typeof error, "");
    } else {
      const commonValidations: { [key: string]: { regex: RegExp, invalidMessage: string } } = {
        current_email: { regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, invalidMessage: "Please enter valid email address." },
        new_email: { regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, invalidMessage: "Please enter valid email address." }
      };
  
      const validation = commonValidations[name];
      if (validation) {
        if (!validation.regex.test(trimmedVal)) {
          setError(name as keyof typeof error, validation.invalidMessage);
        } else {
          setError(name as keyof typeof error, "");
        }
      }
    }
  
    this.setState({ emailError: error });
  }

  desiableEmailCheck = () => {
    const { current_email, new_email } = this.state.emailError;
    return current_email != "" || new_email != "" || this.state.email.current_email == "" || this.state.email.new_email == "";
  }

  saveEmailChange = () => {
    this.setState({ popUpToggle: !this.state.popUpToggle });
  }

  saveEmailPassword = async ()=>{
    const token1 = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };
    const {
      current_email,
      new_email,
      password
    } = this.state.email;

    const payload = {
      current_email,
      new_email,
      password
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editEmailChangeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_email`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigatePage = async ()=> {
    const test = await getStorageData("page",true);
    this.setState({
      tempToggle:test ?? false
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.originalCurrentPassword !== this.state.originalCurrentPassword) {
      if (this.delayInputTimeoutId) {
        clearTimeout(this.delayInputTimeoutId);
      }

      this.delayInputTimeoutId = setTimeout(() => {
      if(this.state.originalCurrentPassword != "")
       this.checkCurrentPassword();
      }, 500);
    }
  }

  checkCurrentPassword= async ()=>{
    const token1 = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const payload = {
      "data": {
          "current_password": this.state.originalCurrentPassword,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currentPasswordCheckId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/passwords/current_password_match`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async componentWillUnmount() {
    if (this.delayInputTimeoutId) {
      clearTimeout(this.delayInputTimeoutId);
    }
  }
  // Customizable Area End
}
