// Customizable Area Start
import React from "react";

import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { image, createIcon } from "./assets";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";
import AddNewMemberController, { Props } from "./AddNewMemberController";
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  ListItemText,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  CardMedia,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const RenameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.5)",
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});
export default class AddNewMember extends AddNewMemberController {
  constructor(props: Props) {
    super(props);
  }

  renderSelectInput() {
    return (
      <>
        <Box mt="1.5rem" style={webStyle.InputSearch} position="relative">
          <style>
            {`
              .basic-multi-select {
                width: 100%;
              }
            `}
          </style>
          <Select
            value={this.state.selectedUsers}
            isMulti
            name="members"
            options={this.state.users
              .filter(
                (user: any) =>
                  !this.state.selectedUsers.some(
                    (selectedUser: any) => selectedUser.value === user.user_name
                  )
              )
              .map((user: any) => ({
                value: user.user_name,
                label: user.user_name,
              }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{
              control: (provided, state) => ({
                ...provided,
                display: "flex",
                flexWrap: "wrap",
                padding: this.state.selectedUsers.length > 0 ? "0.3rem" : "0.9rem",
                border: this.state.memberError ? "2px solid red" : "1px solid #ccc",
                borderRadius: "8px",
                paddingLeft: "1rem",
                boxShadow: "none",
                "&:hover": {
                  border: this.state.memberError ? "2px solid red" : "1px solid #aaa",
                },
              }),
              multiValue: (provided) => ({
                ...provided,
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "26px",
                background: "#E0EDF0",
                padding: "6px 12px",
                fontSize: "14px",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 400,
              }),
              input: (provided) => ({
                ...provided,
                margin: 0,
                padding: "0 6px",
                width: "auto",
                flexGrow: 1,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontFamily: "Poppins",
                paddingLeft: "6px",
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder="Search and add members"
            menuIsOpen={false}
            onChange={(selectedOptions) => {
              this.setState({
                selectedUsers: selectedOptions || [],
                memberError: selectedOptions ? "" : this.state.memberError, 
              });
            }}
            onInputChange={this.handleInputChange}
            data-test-id="select-id"
          />
          <SearchIcon
            style={{
              position: "absolute",
              left: "1%",
              top: "54%",
              transform: "translateY(-50%)",
              color: "grey",
            }}
          />
          <RenameButton
            data-test-id="create-id"
            style={{ marginLeft: "16px" }}
            onClick={this.handleAddMemberData}
          >
            Add
          </RenameButton>
        </Box>
        {this.state.memberError && (
          <Box color="red" mt="0.5rem" data-test-id="error-message">
            {this.state.memberError}
          </Box>
        )}
      </>
    );
  }

  renderMemberList() {
    return (
      <Box style={{ maxHeight: "350px", overflowY: "auto", marginTop: "1rem" }}>
        {this.state.users.length === 0 ? (
          <Typography style={{ color: "#1E293B", fontSize: "16px", textAlign: "center" }}>
            No members present
          </Typography>
        ) : (
          <List>
            {this.state.users
              .filter((user: any) =>
                user.user_name.toLowerCase().includes(this.state.inputName.toLowerCase())
              )
              .map((user: any) => {
                const isSelected = this.state.selectedUsers.some(
                  (selectedUser: any) => selectedUser.value === user.user_name
                );
                return (
                  <ListItem key={user.id} style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px" }}>
                    <ListItemAvatar>
                      <Avatar style={{ background: "#D3E4E9", color: "ABCDD5" }}>
                        <span style={{ fontSize: "18px", fontWeight: 700, paddingTop: "4px" }}>
                          {user.user_name.slice(0, 2).toUpperCase()}
                        </span>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" style={webStyle.ListItemName}>
                          {user.user_name}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" style={webStyle.ListItemEmail}>
                          {user.email}
                        </Typography>
                      }
                    />
                    <IconButton
                      data-test-id="memberAdd-id"
                      onClick={() => this.handleMemberAdd(user)}
                      disabled={isSelected}
                      style={{ color: "#000", opacity:1 }}
                    >
                      <AddIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
          </List>
        )}
      </Box>
    );
  }

  renderModal() {
    return (
      <Modal open={this.state.isAddMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>Add Members to Your Team</Typography>
            <Close data-test-id="close-data-id" onClick={this.handleAddMemberModal} style={{ cursor: "pointer" }} />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Add team members by entering their email addresses below.
          </Typography>
          {this.renderSelectInput()}
          {this.renderMemberList()}
        </Box>
      </Modal>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation}>
          {this.state.messageToggle && (
            <MessageBox
              data-test-id="closeBox-id"
              message={this.state.tostifymessage}
              status={this.state.status}
            />
          )}
          <Loader loading={this.state.loading} />
          <Box style={{ background: "rgb(240, 245, 247)" }}>
            <Box style={webStyle.TopContainer}>
              <Button data-test-id="back-page" style={webStyle.BackBtn} onClick={this.backBtn}>
                <span style={{ fontSize: "20px" }}> &#60; </span>&nbsp; Back
              </Button>
              <Box style={webStyle.TeamName}>
                {this.state.teamName}
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={webStyle.cardStyle}>
              <CardMedia component="img" style={webStyle.image} image={image} />
              <Box sx={webStyle.CardContent}>
                <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>No Members Added</Box>
                <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>
                  Add members to start collaborating.
                </Box>
                <Button
                  data-test-id="btnAddExample"
                  onClick={this.handleOpenModal}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#237182",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "10px 16px 10px 16px",
                    textTransform: "capitalize",
                  }}
                >
                  <span style={webStyle.createIcon}>
                    <img src={createIcon} alt="createIcon" />
                  </span>
                  Add Members
                </Button>
              </Box>
            </Box>
          </Box>
          {this.renderModal()}
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}

const webStyle: any = {
  BackBtn: {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  TeamName: {
    fontSize: "24px",
    fontWeight: "700",
    fontFamily: "poppins",
    paddingLeft: "1rem",
  },

  TopContainer: {
    display: "flex",
    alignItems: "center",
    margin: "1rem",
    alignContent: "center",
    padding: "6px 8px",
  },
  createIcon: {
    marginRight: "10px",
  },
  image: {
    width: "350px",
    height: "350px",
  },
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5rem",
  },
  CardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  renameInputLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#334155",
    lineHeight: "1.375rem",
  },
  errorText: {
    fontSize: "0.75rem",
    color: "#DC2626",
    lineHeight: "1rem",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  InputSearch: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "center",
  },
  ListItemName: {
    fontSize: "16px",
    fontFamily: "poppins",
    lineHeight: "24px",
    fontWeight: "400",
  },
  ListItemEmail: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "22px",
    fontWeight: "400",
  },
};

// Customizable Area End
