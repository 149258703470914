import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string,
  password: string,
  confirmPassword: string,
  agreeToTerms: boolean,
  validations: {
    [key: string]: boolean,
    emailValid: boolean,
    hasUpper: boolean,
    hasLower: boolean,
    hasSpecialChar: boolean,
    hasNumber: boolean,
    minLength: boolean,
    passwordsMatch: boolean,
    termsAccepted: boolean,
  },
  errors: {
    email: string,
    password: string,
    confirmPassword: string,
    terms: string,
    account:string,
    msg:string
  },
  isDisabled: boolean;
  accountError:string; 
  openModal:boolean;
  openTermsCondModal:boolean
  termsdata:Array<object>
  visiblePassword:boolean;
  visibleConfirmPass:boolean;
  originalPassword:string;
  originalPassword1:string;
  event:any;
  asterisk:string;
  asterisk1:string;
  showPassword:boolean
  showPassword1:boolean
  showSecondPart:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailSignUpApiCallId :string =''
  getTokenCheckExpiryId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: '',
      password: '',
      confirmPassword: '',
      agreeToTerms: false,
      validations: {
        emailValid: false,
        hasUpper: false,
        hasLower: false,
        hasSpecialChar: false,
        hasNumber: false,
        minLength: false,
        passwordsMatch: false,
        termsAccepted: false,
      },
      errors: {
        email: '',
        password: '',
        confirmPassword: '',
        terms: '',
        account: '',
        msg: ''
      },
      isDisabled: false,
      accountError:'',
      openModal:false,
      openTermsCondModal:false,
      termsdata:[], 
      visiblePassword:true,
      visibleConfirmPass:true,
      originalPassword:'',
      originalPassword1:'',
      event:"",      
      asterisk:"",
      asterisk1:"",
      showPassword:false,
      showPassword1:false,
      showSecondPart:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.emailSignUpApiCallId) {
        if(responseJson.data && responseJson.data.id) {   
       
          const id = responseJson.data.id;
          const token = responseJson.meta.token;
          
          if (localStorage.getItem("id")) {
            localStorage.removeItem("id");
          }
          if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
          }
    
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          msg.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'Emailnotifications2'
          );
          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          )
          this.send(msg);       
          return;
        }
        this.handleErrorSignup(responseJson);
      }
      if (apiRequestCallId === this.getTokenCheckExpiryId) {
        if (responseJson) {
           if(responseJson.isValidToken){
            this.props.navigation.navigate("CustomisableUserProfiles");
           }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount () {
  super.componentDidMount();
  this.handleValidate()
  this.getTokenCheckExpiryApiCall();
 }

 getTokenCheckExpiryApiCall = () => {
  const token1 = localStorage.getItem("token");
  const header = {
    "Content-Type": "application/json"
  };

  const payload = {
    "token": token1
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getTokenCheckExpiryId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_login/logins/check_token`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

 handleValidate = () => {
  const arr = ['emailValid', 'hasUpper', 'hasLower', 'hasSpecialChar', 'hasNumber', 'minLength', 'passwordsMatch'];
  return arr.map((obj:any) => this.state.validations[obj]).includes(false) || this.state.originalPassword !== this.state.originalPassword1;
 }

  handleErrorSignup=(responseJson:any)=>{
    const emailValid = false;
    if(responseJson.errors.email){
    this.setState({
      validations: { ...this.state.validations, emailValid },
      errors: { ...this.state.errors, email: responseJson.errors.email[0] }
    });
  } else {
    this.setState({
      validations: { ...this.state.validations, emailValid },
      errors: { ...this.state.errors, password: responseJson.errors.password[0] }
    });
  }

  }

    validateConditions = () => {
      const {
        emailValid,
        hasUpper,
        hasLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
        termsAccepted
      } = this.state.validations;
  
      return emailValid && hasUpper && hasLower && hasSpecialChar && hasNumber &&
             minLength && passwordsMatch && termsAccepted &&
             this.state.password === this.state.confirmPassword;
    };
    validateEmail = (email: string) => {
      const emailValid = configJSON.emailValidText.test(email);
      const error = emailValid ? '' : 'Please enter valid  email address.';
    
      this.setState({
        validations: { ...this.state.validations, emailValid },
        errors: { ...this.state.errors, email: error },
      });
    };
    handleContinue = (email:string) => {
 const emailValid = configJSON.emailValidText.test(email);
      const error = emailValid ? '' : 'Please enter valid  email address.';
      if (this.state.email) {
        this.setState({ showSecondPart: true });
      } else {
        this.setState({
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error },
        });
      }
    };

    handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;
      const { value, selectionStart } = input;
      const { originalPassword } = this.state;
      const inputEvent = e.nativeEvent as InputEvent;
      if (!inputEvent || !inputEvent.inputType || value == " " || inputEvent.data == " ") {
        console.error("inputEvent or inputType is undefined");
        return;
      }
      const cursorPosition = selectionStart || 0;
      const data = inputEvent.data || '';
      let updatedPassword = '';
      if (inputEvent.inputType === 'insertText') {
        updatedPassword = 
          originalPassword.slice(0, cursorPosition-1) + data + originalPassword.slice(cursorPosition-1);
          const updatedCursorPosition = cursorPosition + data.length;
          const updatedInput = input;
          updatedInput.setSelectionRange(updatedCursorPosition-1, updatedCursorPosition-1);
      } else {
        updatedPassword = value;
      }
      this.setState({
        originalPassword: updatedPassword,
        asterisk: this.generateAsteriskString(updatedPassword),
        password: updatedPassword,
      }, () => {
        this.validatePassword(this.state.originalPassword);
      });
    };
    
  validatePassword = (password: string) => {
    const hasUpper = configJSON.hasUpper.test(password);
    const hasLower = configJSON.hasLower.test(password);
    const hasSpecialChar = configJSON.hasSpecialChar.test(password);
    const hasNumber = configJSON.hasNumber.test(password);
    const minLength = password.length >= 8;
    const passwordsMatch = this.state.confirmPassword === '' || password === this.state.confirmPassword;
  
    const passwordError = hasUpper && hasLower && hasSpecialChar && hasNumber && minLength ? '' : 'Password is not valid';
    const confirmPasswordError = this.state.confirmPassword && !passwordsMatch ? "Passwords don't match" : '';
  
    this.setState({
      validations: {
        ...this.state.validations,
        hasUpper,
        hasLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
      },
      errors: {
        ...this.state.errors,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      },
    });
  };
  
  handleChangePassword1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const input = e.target as HTMLInputElement;
    const { originalPassword1 } = this.state;
    const cursorPosition = input.selectionStart || 0;
    const inputEvent = e.nativeEvent as InputEvent;
    const data = inputEvent.data || '';
    if (!inputEvent || !inputEvent.inputType || value == " " || inputEvent.data == " ") {
      console.error("inputEvent or inputType is undefined");
      return;
    }
    let orig = originalPassword1;
    if (inputEvent.inputType === 'insertText') {
      orig = originalPassword1.slice(0, cursorPosition-1) + data + originalPassword1.slice(cursorPosition-1);
      const updatedCursorPosition = cursorPosition + data.length;
      const updatedInput = input;
      updatedInput.setSelectionRange(updatedCursorPosition-1, updatedCursorPosition-1);
    } else {
      orig = value;
    }
    this.setState({
      originalPassword1: orig,
      asterisk1: this.generateAsteriskString(orig),
      confirmPassword: orig,
    }, () => {
      this.validateConfirmPassword(this.state.originalPassword1);
    });
  };

  generateAsteriskString = (password: string) => {
    return '*'.repeat(password.length);
  }
    
    validateConfirmPassword = (confirmPassword: string) => {
      const passwordsMatch = confirmPassword === this.state.originalPassword;
      const error = passwordsMatch ? '' : "Passwords don't match";
      this.setState({
        validations: { ...this.state.validations, passwordsMatch },
        errors: { ...this.state.errors, confirmPassword: error },
      });
    };
    
    validateTerms = (agreeToTerms: boolean) => {
      this.setState({
        validations: { ...this.state.validations, termsAccepted: agreeToTerms },
        errors: { ...this.state.errors, terms: agreeToTerms ? '' : 'You must agree to the terms and conditions' },

      });
    }
    

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
  
      this.setState(
        {
          [name]: fieldValue,
          errors: { ...this.state.errors, [name]: '' }
        } as unknown as Pick<S, keyof S>,
        () => {
          switch (name) {
            case 'email':
              this.validateEmail(fieldValue as string);
              break;
            case 'password':
              this.validatePassword(fieldValue as string);
              break;
            case 'confirmPassword':
              this.validateConfirmPassword(fieldValue as string);
              break;
            case 'agreeToTerms':
              this.validateTerms(fieldValue as boolean);
              break;
          }
        }
      );
    };
  
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.validateConditions()) {
      return;
    }
    const header = {
      "Content-Type": "application/json",
    };
  
    const attrs = {
      "email": this.state.email,
      "password": this.state.originalPassword,
      "password_confirmation":this.state.originalPassword1,
      "tnc_status": this.state.agreeToTerms,
      "role_id":0
    };
  
    const httpBody = {
      "data": {
        "attributes": attrs,
      },
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailSignUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSignUp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }; 
  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  toggleVisibility1 = () => {
    this.setState(prevState => ({
      showPassword1: !prevState.showPassword1
    }));
  }

  handleOpenModal = () => this.setState({ openModal: true });
  handleCloseModal = () => this.setState({ openModal: false });
  handleOpenTermsCondModal = () => this.setState({ openTermsCondModal: true });
  handleCloseTermsCondsModal = () => this.setState({ openTermsCondModal: false });
    
  handleLoginNavigate=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg);
  }
  // Customizable Area End
}
