// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, isEmail } from "../../../framework/src/Utilities";
import { IImageUrl, IPresignUrl, ImageUrl } from "./types";
import { Country, State } from "country-state-city";
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { styled, Typography } from "@material-ui/core";
import { JpgIcon, PdfIcon, PngIcon } from "./Icons";
import { mockIcon } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface FormValues {
  projectName: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  developerEmail: string;
  developerFullName: string;
  firstName: string;
  projectDescription: string;
  lastName: string;
  postCode: string;
  city: string;
  email: string;
  streetName: string;
  phoneNumber: string;
  startDate: Date | null;
  endDate: Date | null;
  country: string;
  state: string;
  countryCode: string;
  draft:boolean,
  // web events
  projectImages: IPresignUrl[];
  projectDocuments: IPresignUrl[];
  projectImagesUrl: {
    name: string;
    imageUrl?: ImageUrl;
    imageObj: any;
    id?: number | null;
  }[];
}

interface Shell {
  id: number;
  logo: any;
  name: string;
  amount: number;
}

export interface FileData {
  file_name: string;
  content_type: string;
  image: string;
  size: number;
  error?: string;
}

export type CostPlanFiles = {
  architecturalPlans: FileData[];
  buildingRegulationDrawings: FileData[];
  structuralCalculations: FileData[];
  drainageDrawings: FileData[];
  meDrawings: FileData[];
};

interface FileListExpanded {
  architecturalPlans: boolean;
  buildingRegulationDrawings: boolean;
  structuralCalculations: boolean;
  drainageDrawings: boolean;
  meDrawings: boolean;
}

export type FilesType =
  | "architecturalPlans"
  | "buildingRegulationDrawings"
  | "structuralCalculations"
  | "drainageDrawings"
  | "meDrawings";

interface S {
  planId: number | string | null;
  snackbarOpen: boolean,
  snackbarMessage: string
  isDirty:boolean,
  openDialogLeave:boolean,
  path: string;
  stateList: { isoCode: string; name: string }[];
  countryList: any[];
  anchorEls: any[];
  token: string;
  loading: boolean;
  currentDate: string;
  isDisabled: boolean;
  projectId: string;
  projectDataId: string;
  formValues: FormValues;
  imageUrl: IImageUrl[];
  documentUrl: IImageUrl[];
  images: IPresignUrl[];
  documents: IPresignUrl[];
  openDialog:boolean
  draft:boolean
  formError: {
    projectNameError: string;
    projectDescriptionError: string;
    clientDetailsError: string;
    teamSizeError: string;
    projectUrlError: string;
    developerEmailError: string;
    developerFullNameError: string;
    firstNameError: string;
    lastNameError: string;
    emailError: string;
    streetNameError: string;
    countryCodeError: string;
    cityError: string;
    phoneNumberError: string;
    startDateError: string;
    endDateError: string;
    countryError: string;
    postCodeError: string;
    stateError: string;
  };
  activeStep: number;
  completed: { [k: number]: boolean };
  selectedType: number;
  selectedScopes: number[];
  searchInputText: string;
  shellsSubstructures: Shell[];
  filteredItems: Shell[];
  costPlanFiles: CostPlanFiles;
  fileListExpanded: FileListExpanded;
}

interface SS {
  id: any;
}

const shellsMock = [
  {
    id: 1,
    logo: mockIcon,
    name: "Ground Floor – Timber/Brick",
    amount: 0,
  },
  { id: 2, logo: mockIcon, name: "Pitched Roof", amount: 0 },
  {
    id: 3,
    logo: mockIcon,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
];

const substructuresMock = [
  {
    id: 1,
    logo: mockIcon,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
  { id: 2, logo: mockIcon, name: "Ground Floor – Timber/Brick", amount: 0 },
  {
    id: 3,
    logo: mockIcon,
    name: "Stubbed Attic",
    amount: 0,
  },
];

const RedTextTypography = styled(Typography)({
  color: "#FF0000",
  marginTop: 2,
  fontSize: "small",
  fontFamily: "Poppins",
});

export default class CreateEditProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreateProjectCallId: string = "";
  apiDraftProjectCallId:string="";
  apiGetProjectbyIDListCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      planId: null,
      isDirty:false,
      path: "",
  openDialogLeave:false,
      stateList: [],
      countryList: Country.getAllCountries(),
      anchorEls: [],
      token: "",
      loading: false,
      currentDate: "",
      isDisabled: false,
      projectId: "",
      projectDataId: "",
      openDialog:false,
      draft:false,
      formValues: {
        projectName: "",
        draft:false,
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: null,
        endDate: null,
        developerEmail: "",
        developerFullName: "",
        firstName: "",
        projectDescription: "",
        lastName: "",
        postCode: "",
        city: "",
        email: "",
        streetName: "",
        phoneNumber: "",
        country: "",
        state: "",
        countryCode: "+44",
        projectImages: [],
        projectDocuments: [],
        projectImagesUrl: []
      },
      images: [],
      documents: [],
      imageUrl: [],
      documentUrl: [],
      formError: {
        projectNameError: "",
        projectDescriptionError: "",
        clientDetailsError: "",
        teamSizeError: "",
        projectUrlError: "",
        developerEmailError: "",
        developerFullNameError: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        countryCodeError: "",
        phoneNumberError: "",
        streetNameError: "",
        cityError: "",
        startDateError: "",
        endDateError: "",
        postCodeError: "",
        countryError: "",
        stateError: "",
      },
      activeStep: 0,
      completed: {},
      selectedType: 0,
      selectedScopes: [],
      searchInputText: "",
      shellsSubstructures: [],
      filteredItems: [],
      costPlanFiles: {
        architecturalPlans: [],
        buildingRegulationDrawings: [],
        structuralCalculations: [],
        drainageDrawings: [],
        meDrawings: [],
      },
      fileListExpanded: {
        architecturalPlans: false,
        buildingRegulationDrawings: false,
        structuralCalculations: false,
        drainageDrawings: false,
        meDrawings: false,
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        "Dummy text because Enum does not exist"
      );
      responseData && this.setState({ projectId: responseData });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiCreateProjectCallId) {
        this.createProjectHandleResponse(responseJson);
      } if (apiRequestCallId === this.apiDraftProjectCallId) {
        this.createProjectHandleResponse(responseJson);
      } 
    }
  }

  getPlanId() {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
  };

  async componentDidMount() {
    let token = await getStorageData("token");
    this.setState({ token: token });
    let date = new Date();
    let planId= this.getPlanId();
    this.setState({ currentDate: date.toISOString().split("T")[0], planId });
  }



  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const currentCountry = this.state.formValues.country;
    const previousCountry = prevState.formValues.country;

    if (currentCountry !== previousCountry) {
      const stateList = State.getStatesOfCountry(currentCountry).map(
        (state) => ({
          isoCode: state.isoCode,
          name: state.name,
        })
      );
      this.setState({
        stateList,
        formError: {
          ...this.state.formError,
          stateError: "",
        },
      });
    }
    if (
      (prevState.activeStep === 0 || prevState.activeStep === 2) &&
      this.state.activeStep === 1
    ) {
      this.setState({
        shellsSubstructures: shellsMock,
        filteredItems: shellsMock,
      });
    }
    if (
      (prevState.activeStep === 1 || prevState.activeStep === 3) &&
      this.state.activeStep === 2
    ) {
      this.setState({
        shellsSubstructures: substructuresMock,
        filteredItems: substructuresMock,
      });
    }
  }
  handleStateChange = (event: React.ChangeEvent<any>) => {
    const selectedState = event.target.value;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        state: selectedState,
      },
      formError: {
        ...prevState.formError,
        stateError: "",
      },
    }));
  };

  getSteps() {
    return [
      "Project Information",
      "Select Shells",
      "Select Sub Structures",
      "Select rooms and all",
      "",
    ];
  }

  handleTitle(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectName: text },
      formError: { ...this.state.formError, projectNameError: "" },
    });
  }
  handleProjectDescription(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectDescription: text },
      formError: { ...this.state.formError, projectDescriptionError: "" },
    });
  }
  handleClientDetails(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, clientDetails: text },
      formError: { ...this.state.formError, clientDetailsError: "" },
    });
  }
  handleTeamSize(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, teamSize: text },
      formError: { ...this.state.formError, teamSizeError: "" },
    });
  }
  handleProjecturl(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectUrl: text },
      formError: { ...this.state.formError, projectUrlError: "" },
    });
  }

  handleStartDate(date: Date) {
    this.setState({
      formValues: { ...this.state.formValues, startDate: date },
    });
  }
  handleEndDate(date: Date) {
    this.setState({ formValues: { ...this.state.formValues, endDate: date } });
  }

  handleOpenDialog=()=>{
  this.setState({openDialog:true})
  }
  handleOpenDialogLeave = (path: string) => {
    const { projectName, projectDescription } = this.state.formValues; 
    if (!projectName || !projectDescription) {
        this.props.navigation.navigate(path);
    } else {    
        this.setState({ 
            path, 
            openDialogLeave: true 
        });
    }
};



  handleCloseDialog=()=>{
    this.setState({openDialog:false,openDialogLeave:false,snackbarOpen:false})

  }
  imagePicker = async () => {
    try {
      const imageResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });
      let imgData: IPresignUrl[] = this.state.images;
      let newImageUrls: IImageUrl[] = this.state.imageUrl;

      imageResults.forEach((item: DocumentPickerResponse) => {
        imgData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "image",
        });
      });
      imageResults.forEach((item: DocumentPickerResponse) => {
        newImageUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ images: imgData, imageUrl: newImageUrls });
    } catch (error) {}
  };
  docPicker = async () => {
    try {
      const documentResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.allFiles],
      });
      let documentData: IPresignUrl[] = this.state.documents;
      let newDocumentUrls: IImageUrl[] = this.state.documentUrl;
      documentResults.forEach((item: DocumentPickerResponse) => {
        documentData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "document",
        });
      });
      documentResults.forEach((item: DocumentPickerResponse) => {
        newDocumentUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ documents: documentData, documentUrl: newDocumentUrls });
    } catch (error) {}
  };
  handleEmail(text: string) {
    let regex = isEmail("email", text);
    if (regex.status === false) {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: {
          ...this.state.formError,
          developerEmailError: "Please enter valid email",
        },
      });
      return false;
    } else {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: { ...this.state.formError, developerEmailError: "" },
      });
      return true;
    }
  }
  handleFullName(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, developerFullName: text },
      formError: { ...this.state.formError, developerFullNameError: "" },
    });
  }
  handlePhoneNumber(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, phoneNumber: text },
      formError: { ...this.state.formError, phoneNumberError: "" },
    });
  }

  updateCountryCode = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          countryCode: event.target.value as string,
        },
      },
      this.validateCountryCode
    );
  };

  validateCountryCode = () => {
    const { countryCode } = this.state.formValues;
    let countryCodeErrorMsg = "";
    if (!countryCode) {
      countryCodeErrorMsg = "Select the country code";
    }
    this.setState({
      formError: {
        ...this.state.formError,
        countryCodeError: countryCodeErrorMsg,
      },
    });
  };

  createProjectHandleResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ loading: false });
      this.navigateToType();
    } else if (responseJson.error || responseJson.errors) {
      this.setState({ loading: false, isDisabled: false });
      this.showAlert(
        "Error",
        responseJson.error || responseJson.errors.toString()
      );
    }
  };

  getPlanName = (planId: string | number | null) => {
    switch (planId) {
      case "1":
        return "automated_cost_plan";
      case "2":
        return 'manual_cost_plan';
      case "3":
        return "specialist_cost_plan";
      default:
        break;
    }
  }
  handleSaveDraft = async () => {
    const { formValues, planId } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: false, openDialog: false ,draft:true,openDialogLeave:false,snackbarOpen:true,
      snackbarMessage:"Project Draft Saved Succesfully"});

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.apiDraftProjectCallId = requestMessage.messageId;

    const commonAttributes = {
      address: formValues.streetName,
        country: formValues.country,
      post_code: formValues.postCode,
      address2: formValues.streetName,
      city: formValues.city,
    
    };
    
    const optionalAttributes = planId === 3 
      ? { email: null, name: null, last_name: null, phone: null }
      : { email: formValues.email, name: formValues.firstName, last_name: formValues.lastName, phone: formValues.phoneNumber };
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/projects"
    );

   

    const body = {
      project: {
        plan_type: await this.getPlanName(planId),
        project_name: formValues.projectName,
        description: formValues.projectDescription,
        draft:"true",
        
        addressable_attributes: {
          ...commonAttributes,
          ...optionalAttributes,
        },
      },
    };   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      this.setState({ snackbarOpen: false });
  }, 1000);

  this.props.navigation.navigate(this.state.path)
};


  submitData = async () => {
    const { formValues, planId } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateProjectCallId = requestMessage.messageId;

    const commonAttributes = {
      address: formValues.streetName,
      address2: formValues.streetName,
      city: formValues.city,
      country: formValues.country,
      post_code: formValues.postCode,
    };
    
    const optionalAttributes = planId === 3 
      ? { email: null, name: null, last_name: null, phone: null }
      : { email: formValues.email, name: formValues.firstName, last_name: formValues.lastName, phone: formValues.phoneNumber };
    
    const body = {
      project: {
        plan_type: await this.getPlanName(planId),
        project_name: formValues.projectName,
        description: formValues.projectDescription,
        addressable_attributes: {
          ...commonAttributes,
          ...optionalAttributes,
        },
      },
    };    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createProjectEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInputChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    setFieldValue: (
      field: string,
      value: any
    ) => Promise<void | FormikErrors<FormValues>>
  ) => {
    const { name, value } = event.target;
    if (!name) return;

    const stringValue = String(value);
    window.localStorage.setItem(name, stringValue);

    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [name]: stringValue,
      },
    }));

    setFieldValue(name, value);
  };

  errorDisplay = (
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    field: keyof FormValues
  ) => !!(errors[field] && touched[field]);

  errorText = (
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    field: keyof FormValues
  ) => {
    if (this.errorDisplay(errors, touched, field)) {
      return <RedTextTypography>{errors[field]}</RedTextTypography>;
    }
  };

  handleScopeClick = (selectedScope: number) => {
    if (this.state.selectedScopes.includes(selectedScope)) {
      this.setState((prev) => ({
        selectedScopes: prev.selectedScopes.filter(
          (scope) => scope !== selectedScope
        ),
      }));
    } else {
      this.setState((prev) => ({
        selectedScopes: [...prev.selectedScopes, selectedScope],
      }));
    }
  };

  handleTypeClick = (selectedType: number) => this.setState({ selectedType });

  handleAmountChange = (id: number, action: string) => {
    const itemsCopy = this.state.shellsSubstructures;
    const selectedItemIndex = itemsCopy.findIndex((item) => item.id === id);
    switch (action) {
      case "add":
        itemsCopy[selectedItemIndex].amount += 1;
        break;
      case "remove":
        if (itemsCopy[selectedItemIndex].amount !== 0) {
          itemsCopy[selectedItemIndex].amount -= 1;
        }
        break;
      default:
        break;
    }
    this.setState({ shellsSubstructures: itemsCopy });
  };

  planLogo = (type: string, isError: boolean) => {
    const iconColor = isError ? "#DC2626" : "#237182";
    switch (type) {
      case "image/jpeg":
      case "image/jpg":
        return <JpgIcon color={iconColor} />;
      case "image/png":
        return <PngIcon color={iconColor} />;
      default:
        return <PdfIcon color={iconColor} />;
    }
  };

  uploadCostPlanFile = (file: File, type: FilesType) => {
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const sizeString = () => {
        if (file.size < 1024) {
          return file.size + " Bytes";
        } else if (file.size >= 1024 && file.size < 1048576) {
          return Math.round(file.size / 1024) + " KB";
        } else {
          return Math.round(file.size / 1048576) + " MB";
        }
      };

      if (!allowedTypes.includes(file.type)) {
        this.setState((prev) => ({
          costPlanFiles: {
            ...prev.costPlanFiles,
            [type]: [
              ...prev.costPlanFiles[type],
              {
                file_name: file.name,
                content_type: file.type,
                image: "",
                size: file.size,
                error: "Invalid file format.",
              },
            ],
          },
        }));
        return;
      }

      if (file.size > 10485760) {
        this.setState((prev) => ({
          costPlanFiles: {
            ...prev.costPlanFiles,
            [type]: [
              ...prev.costPlanFiles[type],
              {
                file_name: file.name,
                content_type: file.type,
                image: "",
                size: file.size,
                error: "The file is too large to upload.",
              },
            ],
          },
        }));
        return;
      }

      const fileNames = Object.values(this.state.costPlanFiles)
        .flat(1)
        .map((item) => item.file_name);

      if (fileNames.includes(file.name)) {
        this.setState((prev) => ({
          costPlanFiles: {
            ...prev.costPlanFiles,
            [type]: [
              ...prev.costPlanFiles[type],
              {
                file_name: file.name,
                content_type: file.type,
                image: "",
                size: file.size,
                error: "File already added.",
              },
            ],
          },
        }));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState((prev) => ({
          costPlanFiles: {
            ...prev.costPlanFiles,
            [type]: [
              ...prev.costPlanFiles[type],
              {
                file_name: file.name,
                content_type: file.type,
                image: reader.result as string,
                size: sizeString(),
              },
            ],
          },
        }));
      };
      reader.readAsDataURL(file); 
    }
  };

  handleFilesCollapse = (value: FilesType) =>
    this.setState((prev) => ({
      fileListExpanded: {
        ...prev.fileListExpanded,
        [value]: !prev.fileListExpanded[value],
      },
    }));

  handleFileDelete = (fileName: string, type: FilesType) => {
    const fileListCopy = this.state.costPlanFiles[type];
    const filteredList = fileListCopy.filter(
      (file) => file.file_name !== fileName
    );
    this.setState((prev) => ({
      costPlanFiles: {
        ...prev.costPlanFiles,
        [type]: filteredList,
      },
      fileListExpanded: {
        ...prev.fileListExpanded,
        [type]: false,
      },
    }));
  };

  isSubmitDisabled = () => {
    const allFilesArr = Object.values(this.state.costPlanFiles).flat(1);
    const isError = allFilesArr.some((file) => file.error);
    const isEmpty =
      !this.state.costPlanFiles.architecturalPlans.length ||
      !this.state.costPlanFiles.buildingRegulationDrawings.length ||
      !this.state.costPlanFiles.structuralCalculations.length;
    return isError || isEmpty;
  };

  navigateToType = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectType"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToShells = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolioShells"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleSearchInputChange = (search: string) => {
    const filteredItems = this.state.shellsSubstructures.filter((shell) =>
      shell.name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ filteredItems, searchInputText: search });
  };

  navigateToScopes = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectScope"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goBack = () => {
    if (this.state.activeStep <= 1) {
      this.props.navigation.goBack();
    } else {
      this.setState((prev) => ({ activeStep: prev.activeStep - 1 }));
    }
  };
}

// Customizable Area End
